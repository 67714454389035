import styled from 'styled-components';
import Text from '@deprecated-ui/common/Text';
import Flex from '@deprecated-ui/common/Flex';
import device from '@deprecated-ui/_style/device';
import color from '@deprecated-ui/_style/color';
import { Wrapper } from '../styled';

const SectionWrapper = styled(Wrapper)`
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LeftSection = styled(Flex)`
  gap: 40px;
  flex-direction: column;
  span {
    max-width: 488px;
    font-size: 18px;
    line-height: 26px;
    color: #575757;
  }
`;

const Title = styled(Text).attrs({
  as: 'h3',
  family: 'bold',
  align: 'center',
  mb: '20px',
})`
  font-size: 25.5px;

  @media ${device.tablet} {
    font-size: 48px;
    line-height: 60px;
  }
`;

const LeftTitle = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  .green {
    color: ${color.primary};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  a {
    color: ${color.primary};
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    width: 344px;
    display: flex;
    border: 1px solid;
    height: 44px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  img {
    background: #3b3b3b;
    border-radius: 6px;
    max-width: 100vw;
  }
  @media ${device.tablet} {
    img {
      max-width: 100vw;
    }
  }
`;
export { SectionWrapper, Title, LeftSection, LeftTitle, ButtonsWrapper };
