import ReactVisibilitySensor from 'react-visibility-sensor';
import Image from 'global/Image';
import Link from 'next/link';

import {
  SectionWrapper,
  Title,
  LeftSection,
  LeftTitle,
  ButtonsWrapper,
} from './styled';

import DownloadButtons from '../../download/DownloadButtons';

function DesktopApp() {
  return (
    <ReactVisibilitySensor offset={{ top: 140, bottom: 140 }} partialVisibility>
      <SectionWrapper>
        <LeftSection>
          <LeftTitle>
            <Title>Stockbit Tersedia versi </Title>
            <Title className="green">Desktop App</Title>
          </LeftTitle>
          <span className="content">
            Pengalaman trading lebih Profesional dengan Aplikasi Saham versi
            Desktop untuk MacOS dan Windows.
          </span>
          <ButtonsWrapper>
            <Link href="/desktop" className="explore">
              Mulai Jelajahi
            </Link>
            <DownloadButtons source="Stockbit Main Page" />
          </ButtonsWrapper>
        </LeftSection>
        <Image src="/images/desktop-monitor.svg" />
      </SectionWrapper>
    </ReactVisibilitySensor>
  );
}

export default DesktopApp;
